<template>
  <!-- ====== Navbar Section Start -->
  <nav
    class="bg-white fixed w-full z-20 top-0 left-0 border-b border-gray-200 z-50 shadow"
  >
    <div class="flex flex-wrap items-center justify-between mx-auto p-4">
      <a href="https://hypercube.dj" class="flex items-center">
        <img src="/brand.png" class="h-9 mr-3" alt="Hypercube Logo" />
      </a>
      <div class="flex md:order-2">
        <button
          type="button"
          @click="modal = true"
          class="text-pink-600 hover:text-white focus:text-white bg-white border border-pink-600 hover:bg-pink-500 focus:bg-pink-500 focus:border-pink-500 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0"
        >
          Recevoir un devis
        </button>
        <button
          data-collapse-toggle="navbar-sticky"
          type="button"
          class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-pink-100 focus:outline-none focus:ring-2 focus:ring-pink-200"
          aria-controls="navbar-sticky"
          aria-expanded="false"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
      </div>
      <div
        class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
        id="navbar-sticky"
      >
        <ul
          class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white"
        >
          <li>
            <a
              href="/home"
              class="block py-2 pl-3 pr-4 rounded md:bg-transparent uppercase md:p-0"
              :class="[
                $route.path == '/'
                  ? 'text-white bg-pink-500 md:text-pink-500'
                  : 'text-gray-900 md:hover:text-pink-500',
                $route.path == '/home'
                  ? 'text-white bg-pink-500 md:text-pink-500'
                  : 'text-gray-900 md:hover:text-pink-500',
              ]"
              aria-current="page"
              >Accueil</a
            >
          </li>
          <li class="relative" ref="targetElement">
            <button
              class="flex items-center py-2 pl-3 pr-4 rounded md:bg-transparent uppercase md:p-0"
              :class="[
                $route.path == '/consulting'
                  ? 'text-white bg-pink-500 md:text-pink-500'
                  : 'text-gray-900 md:hover:text-pink-500',
                $route.path == '/networkSecurity'
                  ? 'text-white bg-pink-500 md:text-pink-500'
                  : 'text-gray-900 md:hover:text-pink-500',
                $route.path == '/digitalDev'
                  ? 'text-white bg-pink-500 md:text-pink-500'
                  : 'text-gray-900 md:hover:text-pink-500',
              ]"
              @click="dropdownDividerButton"
            >
              Nos Expertises
              <svg
                class="w-2.5 h-2.5 ms-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            <div
              v-if="dropdownDivider"
              class="absolute top-8 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-48"
            >
              <ul class="py-2 text-sm text-gray-700">
                <li>
                  <a
                    href="/consulting"
                    class="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap"
                    >Consulting TIC</a
                  >
                </li>
                <li>
                  <a
                    href="/networkSecurity"
                    class="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap"
                    >Réseau &amp; Sécurité</a
                  >
                </li>
                <li>
                  <a
                    href="/digitalDev"
                    class="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap"
                    >Développement Digital</a
                  >
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a
              href="/contactus"
              class="block py-2 pl-3 pr-4 rounded md:bg-transparent uppercase md:p-0"
              :class="
                $route.path == '/contactus'
                  ? 'text-white bg-pink-500 md:text-pink-500'
                  : 'text-gray-900 md:hover:text-pink-500'
              "
              >Contactez-Nous</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- ====== Navbar Section End -->
  <modalComp :open="modal" @closeModal="(val) => (modal = val)" />
</template>
<script>
import modalComp from "./Modals.vue";
export default {
  name: "NavComp",
  components: {
    modalComp,
  },
  data() {
    return {
      dropdownDivider: false,
      modal: false,
    };
  },
  methods: {
    dropdownDividerButton() {
      this.dropdownDivider = !this.dropdownDivider;
    },
    hideDropdownDivider(event) {
      const targetElement = this.$refs.targetElement;
      if (!targetElement.contains(event.target)) {
        this.dropdownDivider = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.hideDropdownDivider);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.hideDropdownDivider);
  },
};
</script>
