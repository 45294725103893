<template lang="">
  <!-- Estimation modal -->
  <div
    v-if="open"
    tabindex="-1"
    class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-lg max-h-full m-auto mt-24 z-20">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow z-30">
        <!-- Modal header -->
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t"
        >
          <h3 class="text-xl font-semibold text-gray-900">
            Formulaire de consulting
          </h3>
          <button
            type="button"
            @click="closeModal"
            class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="authentication-modal"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 md:p-5">
          <form class="space-y-4" action="#">
            <div>
              <label
                for="yourname"
                class="block mb-2 text-sm font-medium text-gray-900"
                >Votre prénom et nom</label
              >
              <input
                type="yourname"
                name="yourname"
                id="yourname"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5 outline-none"
                placeholder="Farah Mehdi"
                required
              />
            </div>
            <div>
              <label
                for="youremail"
                class="block mb-2 text-sm font-medium text-gray-900"
                >Votre e-mail</label
              >
              <input
                type="youremail"
                name="youremail"
                id="youremail"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5 outline-none"
                placeholder="mehdi.farah@company.com"
                required
              />
            </div>
            <div>
              <div>
                <multiselect
                  v-model="value"
                  :options="options"
                  placeholder="Selectionner un service"
                  :allow-empty="false"
                ></multiselect>
              </div>
            </div>
            <div>
              <label
                for="description"
                class="block mb-2 text-sm font-medium text-gray-900"
                >Parlez-nous de votre projet</label
              >
              <textarea
                name="description"
                id="description"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5 outline-none resize-none"
                cols="30"
                rows="10"
                placeholder="Qu'est-ce qu'il vous faut ?"
                required
              ></textarea>
            </div>
            <div class="block text-right">
              <button
                type="submit"
                class="inline-flex items-center text-white bg-pink-500 hover:bg-pink-800 focus:ring-4 focus:outline-none focus:ring-pink-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                <span class="mr-4">Soumettre</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="14"
                  viewBox="0 0 448 512"
                  class="fill-current"
                >
                  <path
                    d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="fixed top-0 left-0 bottom-0 right-0 bg-gray-800 opacity-40 z-10"
    ></div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "modalsComponent",
  components: {
    Multiselect,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      options: [
        "Consulting TIC",
        "Réseau et Sécurité",
        "Développement Digitale",
      ],
    };
  },
  methods: {
    closeModal: function () {
      this.$emit("closeModal", false);
    },
  },
};
</script>
